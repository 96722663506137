import React, {useState, useEffect} from 'react';
import './App.scss';
import { Link, Element , Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import WOW from 'wowjs';
import {useTransition, animated} from 'react-spring';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Map from './components/Map';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

import Footer from './components/Footer';
import $ from "jquery";







function App() {
  let bottom = $('.home-wrapper').offsetTop;
  $(window).scroll(function(){    
    if ($(this).scrollTop() > bottom){ 
        $('.nav-wrapper-scroll').addClass('active-scroll'); 
      }
    else{
        $('.nav-wrapper-scroll').removeClass('active-scroll');
      }
    });
  const [showMenu, SetMenu] = useState(false)




  const [show, set] = useState(false)
  
  const transitions = useTransition(show, null, {
    from: {  opacity: 0, transform: 'translateX(100%)'},
    enter: {  
      position: 'fixed',
      width: '100vw',
      opacity: 1, 
      transform: 'translateX(0)',
      zIndex: 2000,

  },
    leave: { opacity: 0, transform: 'translateX(100%)' },
  })
  
  useEffect(() => {
    new WOW.WOW().init();
  });



  return (



    <div className="main-wrapper  ">

      <div className="bar" role="bar">
        <div className="peg" />
      </div>
      <div className="spinner" role="spinner">
        <div className="spinner-icon" />
      </div>
      

      {
        transitions.map(({ item, key, props }) =>
        item && 
        <animated.div key={key} style={props}>
          <section className="contact-wrapper">
          <div className="contact-container">
            <div className="contact-panel01">
            <div id="map" className="map">
                <Map />
             </div>
            </div>
            <div className="contact-panel02">
              <div onClick={() => set(false)} className="close" id="close-x">X</div>
              <div className="contact-head-text">WE’D BE HAPPY TO HEAR YOUR 
              <br /> REQUEST CONTACT US!</div>
              <form >
                <div className="form-group">
                <TextField id="standard-basic" color="primary" className="white" label="Name" />
                <TextField id="standard-basic" color="primary" label="Family" />
                </div>
                <TextField id="standard-full-width"
                    label="Email" 
                    fullWidth
                    margin="normal"
                   
                />
                <TextField id="standard-full-width"
                    label="Subject" 
                    fullWidth
                    margin="normal"
                />
                <TextField id="standard-full-width"
                    label="Message" 
                    
                    fullWidth
                    margin="normal"
                />
                <Button variant="contained" color="secondary">
                  Submit!
                </Button>
              </form>
              <div className="footer-contact">
                <div className="ft-01">
                  <div className="ft-title">MAIN OFFICE:</div>
                  <div className="ft-copy">PEGASUS AIR SERVICES HANGAR,
                    <br /> PLARIDEL AIRPORT, BARANGAY LUMANG BAYAN,
                    <br /> PLARIDEL PHILIPPINES 3007
                    <br />
                    <br /> Tel. No.: +63 (0) 44. 794.55.73 
                    <br /> Mobile No.: +63 (0) 919.081.8120
                    <br /> Mobile No.: +63 (0) 919.081.8120
                  </div>
                </div>
                <hr />
                <div className="ft-01">
                  <div className="ft-title">MAIN OFFICE:</div>
                  <div className="ft-copy">PEGASUS AIR SERVICES HANGAR,
                    <br /> INTERISLAND RESORTS & SERVICES INC. OFFICE
                    <br /> DOMESTIC ROAD, PASAY CITY 
                    <br />
                    <br /> Tel. No.: +63 (0) 2 851.17.31
                    <br /> Mobile No.: +63 (0) 921.467.6678
                    <br /> Mobile No.: +63 (0) 966.213.9267
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        </animated.div>
        )
        
      }    
      <div className="home-wrapper">

         <section className="nav-wrapper-scroll">
          <div className="nav-container">
            <div className="nav-logo">
              <img src="/img/general/pegasus_logo copy.png" alt />
            </div>
            <div className="nav-list">
              <ul>
                <Link activeClass="active" to="aerial-works" spy={true} smooth={true} offset={50} duration={500}  to="aerial-works" ><li><a>AERIAL WORKS</a></li></Link>
                <Link activeClass="active" to="aerial-works" spy={true} smooth={true} offset={50} duration={500}  to="fbo"><li> <a >FIXED BASE OPERATOR</a></li></Link>
                <Link activeClass="active" to="aerial-works" spy={true} smooth={true} offset={50} duration={500}  to="aircraft-maintenance"><li><a >AIRCRAFT MAINTENANCE</a></li></Link>
                <Link onClick={() => set(!show)}  ><li><a  className="open-contact" >CONTACT US</a></li></Link>
              </ul>
            </div>
          </div>
        </section>


          <section className="nav-wrapper">
            <div className="nav-container">
              <div className="nav-logo" />
              <div className="nav-list">
                <ul>
                    <Link activeClass="active" to="aerial-works" spy={true} smooth={true} offset={50} duration={500}  to="aerial-works" ><li><a>AERIAL WORKS</a></li></Link>
                    <Link activeClass="active" to="aerial-works" spy={true} smooth={true} offset={50} duration={500}  to="fbo"><li> <a >FIXED BASE OPERATOR</a></li></Link>
                    <Link activeClass="active" to="aerial-works" spy={true} smooth={true} offset={50} duration={500}  to="aircraft-maintenance"><li><a >AIRCRAFT MAINTENANCE</a></li></Link>
                    <Link onClick={() => set(!show)}  ><li><a  className="open-contact" >CONTACT US</a></li></Link>
                </ul>
              </div>
              <div className="nav-contacts">
                <div className="nav-contact-label">MAIN OFFICE</div>
                <div className="nav-contact-item">+63 (0) 44 794.55.35</div>
                <div className="nav-contact-label">MANILA OFFICE</div>
                <div className="nav-contact-item">+63 (0) 2 851.17.31</div>
                <div className="icon-holders">
                  <div className="ficons">
                    <FontAwesomeIcon icon={faFacebook} size="2x" color="darkblue"  />
                  </div>
                  <div className="ficons">
                    <FontAwesomeIcon icon={faTwitter} size="2x" color="darkblue" />
                  </div>
                  <div className="ficons">
                  < FontAwesomeIcon icon={faYoutube} size="2x" color="darkblue" />
                  </div>
                  <div className="ficons">
                    <FontAwesomeIcon icon={faInstagram} size="2x" color="darkblue" />
                  </div>

                  
                </div>
              </div>
            </div>
          </section>

          <section className="home-wrapper-dev">
            <div className="home-container">
              <div className="home-label-wrap">
                <div className="home-label"><span className="bg-span">Excellent and Professional </span><br /><span className="sm-span">provider of high-profile aerial services</span></div>
                <Link activeClass="active" to="aerial-works" spy={true} smooth={true} offset={50} duration={500}  to="aerial-works" ><div  className="home-button">EXPLORE</div></Link> 
              </div>
              <div className="home-carousel-wrapper">
                <div>
                  <img alt="" src="/img/pegasus1.jpg" />
                </div>
                <div>
                  <img alt="" src="/img/pegasus2.jpg" />
                </div>
                <div>
                  <img alt="" src="/img/pegasus3.jpg" />
                </div>
                <div>
                  <img alt="" src="/img/pegasus4.jpg" />
                </div>
              </div>
            </div>
          </section>
          </div> 



      <div className="content-wrapper">
        
        <section name="aerial-works" className="aerial-works-wrapper">
          <div className="aerial-works-content">
            <div className="banner-towing">
              <div className="side-sky">
                <div className="sky" />
              </div>
              <div className="banner-towing-content">
                <div data-wow-delay="1s" className="ps-title-wrapper m-top-20vh wow fadeInUp">
                  <div className="ps-head-label">AERIAL WORKS</div>
                  <div className="ps-label">BANNER TOWING</div>
                </div>
                <div data-wow-delay="1s" className="banner-towing-title m-top-10vh wow fadeInUp">REACH YOUR TARGET AUDIENCE
                  <br /> INSTALLY AND EFFECTIVELY</div>
                <div data-wow-delay="1s" data-wow-duration="2s" className="aircraft-socata m-top-15vh wow slideInRight" />
                <div className="full-width flex-display-full m-bot-5vh wow fadeInUp">
                  <div className="sub-text m-top-50vh text-gray">The most effective form of advertising where the audience actually pays attention and read or view your message</div>
                </div>
                <hr />
                <div className="m-top-5vh" />
                <div className="panel-flex-full">
                  <div data-wow-delay="1s" className="ps-title-wrapper m-top-20vh wow fadeInUp">
                    <div className="ps-label">LETTER BANNER</div>
                  </div>
                  <div data-wow-delay="1s" className="banner-pics m-top-5vh m-bot-5vh wow fadeInUp"><img alt="" src="/img/aerial_works/letter_banner.jpg" /></div>
                  <div onClick={() => set(!show)} data-wow-delay="1s" className="btn pink darken-1  inquire-btn wow fadeInUp"><a className="open-contact">INQUIRE</a></div>
                </div>
                <div className="m-top-20vh" />
                <hr />
                <div className="panel-flex-full">
                  <div data-wow-delay="1s" className="ps-title-wrapper m-top-20vh wow fadeInUp">
                    <div className="ps-label">BILLBOARD BANNER</div>
                  </div>
                  <div data-wow-delay="1s" className="banner-pics m-top-5vh m-bot-5vh wow fadeInUp"><img alt="" src="/img/aerial_works/banner_towing.jpg" /></div>
                  <div data-wow-delay="1s" className="btn pink darken-1  inquire-btn wow fadeInUp"><a className="open-contact">INQUIRE</a></div>
                </div>
                <div className="m-top-20vh" />
                <hr />
                <div className="panel-flex-full">
                  <div data-wow-delay="1s" className="ps-title-wrapper m-top-20vh wow fadeInUp">
                    <div className="ps-label">COMBINATION BANNER</div>
                  </div>
                  <div data-wow-delay="1s" className="banner-pics m-top-5vh m-bot-5vh wow fadeInUp"><img alt="" src="/img/aerial_works/combination.jpg" /></div>
                  <div data-wow-delay="1s" className="btn pink darken-1  inquire-btn wow fadeInUp"><a className="open-contact">INQUIRE</a></div>
                </div>
                <div className="m-top-20vh" />
                <hr />
              </div>
            </div>
            <div className="scenic-flights">
              <div className="scenic-cover">
                <div className="panel-relative panel-flex-full-right flex-column">
                  <div data-wow-delay="2s" className="scenic-sub-title wow fadeInUp">PEGASUS AIR SERIVES INC.</div>
                  <div data-wow-delay="1s" className="scenic-title wow fadeInUp"><span className="scenic-title-white">SCENIC</span><span className="scenic-title-pink">FLIGHTS</span></div>
                  <div data-wow-delay="1s" className="scenic-copy wow fadeInUp">Be mesmerized by the beauty of the Mt. Pinatubo and other aerial scenery near Bulacan, A challenging destination worthy of exploration and feel the thrill above the most beautiful body of water here in the Philippines within
                    12 to 55 minutes. </div>
                </div>
                <div className="panel-relative">
                  <div data-wow-delay="1s" data-wow-duration="2s" className="aircraft-cherokee wow fadeInUp" />
                </div>
              </div>
              <div className="scenic-content">
                <div data-wow-delay="1s" className="scenic-content-copy wow fadeInUp">Enjoy the flight above Taal and discover the beauty as you go down closer to the smallest volcano in the world. Flying a little more and be mesmerized with Tagaytay rolling hills and winding roads.Explore the unending sights
                  of mountain curves and coves of Nasugbu, Balayan and Calatagan of Batangas.
                  <br /><br /> Also view the sun, the sand and the sea of Puerto Galera and accross the island cruise above Corregidor and relive its historic past.
                  <br /><br /> All scenic flights require advance reservations and a minimum of two persons per flight.</div>
              </div>
              <div className="scenic-thumbnails-wrapper">
                <div data-wow-delay="1s" className="ps-title-wrapper m-top-20vh m-bot-5vh wow fadeInUp">
                  <div className="ps-label">FROM TUY (BATANGAS)</div>
                </div>
                <div data-wow-delay="1s" className="scenic-thumnail-panel m-bot-20vh wow fadeInUp">
                  <div className="scenic-thumb">
                    <div className="scenic-thumb-img"><img alt="" src="/img/aerial_works/thumbs/B1.jpg" /></div>
                    <div className="scenic-thumb-title bg-torquise">TAAL VOLCANO</div>
                    <div className="scenic-details">
                      <div className="scenic-price">PHP 6,600.00</div>
                      <div className="scenic-time"> 25 mins.</div>
                    </div>
                  </div>
                  <div className="scenic-thumb">
                    <div className="scenic-thumb-img"><img alt="" src="/img/aerial_works/thumbs/B2.jpg" /></div>
                    <div className="scenic-thumb-title bg-red01">NASUGBO</div>
                    <div className="scenic-details">
                      <div className="scenic-price">PHP 5,226.00</div>
                      <div className="scenic-time"> 15-18 mins.</div>
                    </div>
                  </div>
                  <div className="scenic-thumb">
                    <div className="scenic-thumb-img"><img alt="" src="/img/aerial_works/thumbs/B3.jpg" /></div>
                    <div className="scenic-thumb-title bg-blue01">BALAYAN BAY</div>
                    <div className="scenic-details">
                      <div className="scenic-price">PHP 5,226.00</div>
                      <div className="scenic-time"> 15-18 mins.</div>
                    </div>
                  </div>
                  <div className="scenic-thumb">
                    <div className="scenic-thumb-img"><img alt="" src="/img/aerial_works/thumbs/B4.jpg" /></div>
                    <div className="scenic-thumb-title bg-violet01">CALATAGAN</div>
                    <div className="scenic-details">
                      <div className="scenic-price">PHP 9,146.00</div>
                      <div className="scenic-time"> 25 mins.</div>
                    </div>
                  </div>
                  <div className="scenic-thumb">
                    <div className="scenic-thumb-img"><img alt="" src="/img/aerial_works/thumbs/B5.jpg" /></div>
                    <div className="scenic-thumb-title bg-green01">PUERTO GALERA</div>
                    <div className="scenic-details">
                      <div className="scenic-price">PHP 11,760.00</div>
                      <div className="scenic-time"> 45 mins.</div>
                    </div>
                  </div>
                  <div className="scenic-thumb">
                    <div className="scenic-thumb-img"><img alt="" src="/img/aerial_works/thumbs/B6.jpg" /></div>
                    <div className="scenic-thumb-title bg-green02">CORREGIDOR</div>
                    <div className="scenic-details">
                      <div className="scenic-price">PHP 11,760.00</div>
                      <div className="scenic-time"> 45 mins.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="scenic-thumbnails-wrapper">
                <div data-wow-delay="1s" className="ps-title-wrapper m-top-20vh m-bot-5vh wow fadeInUp">
                  <div className="ps-label">FROM PLARIDEL (BULACAN)</div>
                </div>
                <div data-wow-delay="1s" className="scenic-thumnail-panel m-bot-20vh wow fadeInUp">
                  <div className="scenic-thumb">
                    <div className="scenic-thumb-img"><img alt="" src="/img/aerial_works/thumbs/1A.jpg" /></div>
                    <div className="scenic-thumb-title bg-pink01">MT. PINATUBO</div>
                    <div className="scenic-details">
                      <div className="scenic-price">PHP 14,300.00</div>
                      <div className="scenic-time"> 1 Hour</div>
                    </div>
                  </div>
                  <div className="scenic-thumb">
                    <div className="scenic-thumb-img"><img alt="" src="/img/aerial_works/thumbs/1B.jpg" /></div>
                    <div className="scenic-thumb-title bg-red01">LA MESA ECO PARK</div>
                    <div className="scenic-details">
                      <div className="scenic-price">PHP 9,100.00</div>
                      <div className="scenic-time"> 30-35 mins.</div>
                    </div>
                  </div>
                  <div className="scenic-thumb">
                    <div className="scenic-thumb-img"><img alt="" src="/img/aerial_works/thumbs/1C.jpg" /></div>
                    <div className="scenic-thumb-title bg-torquise">MANILA BAY</div>
                    <div className="scenic-details">
                      <div className="scenic-price">PHP 4,600.00</div>
                      <div className="scenic-time"> 12-18 mins.</div>
                    </div>
                  </div>
                  <div className="scenic-thumb">
                    <div className="scenic-thumb-img"><img alt="" src="/img/aerial_works/thumbs/vt-aerial (2).jpg" /></div>
                    <div className="scenic-thumb-title bg-yellow01">ANGAT DAM</div>
                    <div className="scenic-details">
                      <div className="scenic-price">PHP 4,600.00</div>
                      <div className="scenic-time"> 12-18 mins.</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="aw-others-wrapper">
              <div data-wow-delay="1s" className="aw-thumbs-container wow fadeInUp">
                <div className="aw-thumbnails">
                  <div className="aw-thumb-title">PERSONAL MESSAGE</div>
                  <div className="aw-thumb-img-wrapper">
                    <div className="aw-thumb-img">
                      <img alt="" src="/img/aerial_works/aerial/1.jpg" alt />
                    </div>
                  </div>
                  <div className="aw-thumb-content-wrapper">
                    <div className="aw-thumb-content">Make every occasion special and memorable with an aerial message high in the sky for all to see. Whether you’re looking to surprise your special someone with a marriage proposal, celebrate a milestone birthday or anniversary,
                      or communicate a special announcement, Pegasus Air Services can add a special spark to any occasion!</div>
                  </div>
                  <div className="aw-thumb-button-wrapper">
                    <div className="aw-thumb-button"><a href>INQUIRE</a></div>
                  </div>
                </div>
                <div className="aw-thumbnails">
                  <div className="aw-thumb-title">CONFETTI DROPINGS</div>
                  <div className="aw-thumb-img-wrapper">
                    <div className="aw-thumb-img">
                      <img alt="" src="/img/aerial_works/aerial/2.jpg" alt />
                    </div>
                  </div>
                  <div className="aw-thumb-content-wrapper">
                    <div className="aw-thumb-content">Make every occasion special and memorable with an aerial message high in the sky for all to see. Whether you’re looking to surprise your special someone with a marriage proposal, celebrate a milestone birthday or anniversary,
                      or communicate a special announcement, Pegasus Air Services can add a special spark to any occasion!</div>
                  </div>
                  <div className="aw-thumb-button-wrapper">
                    <div className="aw-thumb-button"><a href>INQUIRE</a></div>
                  </div>
                </div>
                <div className="aw-thumbnails">
                  <div className="aw-thumb-title">AERIAL PHOTOGRAPHY</div>
                  <div className="aw-thumb-img-wrapper">
                    <div className="aw-thumb-img">
                      <img alt="" src="/img/aerial_works/aerial/3.jpg" alt />
                    </div>
                  </div>
                  <div className="aw-thumb-content-wrapper">
                    <div className="aw-thumb-content">Pegasus can coordinate with you and our professional photographer for top quality aerial photo or video shoots to deliver the best possible images in a cost effective and timely manner. Our services are a perfect resource
                      for civil engineers, architects, surveyors, real estate and land developers, corporate portfolios and presentations, personal homes and farms, ecological study programs, conservation and agriculture, and more!</div>
                  </div>
                  <div className="aw-thumb-button-wrapper">
                    <div className="aw-thumb-button"><a href>INQUIRE</a></div>
                  </div>
                </div>
                <div className="aw-thumbnails">
                  <div className="aw-thumb-title">AERIAL SURVEY</div>
                  <div className="aw-thumb-img-wrapper">
                    <div className="aw-thumb-img">
                      <img alt="" src="/img/aerial_works/aerial/4.jpg" alt />
                    </div>
                  </div>
                  <div className="aw-thumb-content-wrapper">
                    <div className="aw-thumb-content">If you need to comprehensively assess large areas of land, there’s no better way to do so than in the air. Our fleet of airplanes can assist surveys for archeology, fishery, farm monitoring, real estate development,
                      local government needs, topography, mining, wildlife research, and more. An aerial view can provide you with information you could not otherwise achieve from ground level assessments.</div>
                  </div>
                  <div className="aw-thumb-button-wrapper">
                    <div className="aw-thumb-button"><a href>INQUIRE</a></div>
                  </div>
                </div>
                <div className="aw-thumbnails">
                  <div className="aw-thumb-title">CLOUDSEEDING</div>
                  <div className="aw-thumb-img-wrapper">
                    <div className="aw-thumb-img">
                      <img alt="" src="/img/aerial_works/aerial/5.jpg" alt />
                    </div>
                  </div>
                  <div className="aw-thumb-content-wrapper">
                    <div className="aw-thumb-content">Let it rain, let it rain! Yes, we can help you with modifying precipitation patterns in your area! If your town is suffering from a severe drought and cloud seeding is the solution, Pegasus Air Services can assist you
                      and your weather experts in your cloud seeding efforts. Working with us can ensure that cloud seeding particles are dispersed over farm and agricultural locations that are in dire need of precipitation and are a
                      lifeline of food resources for thecommunity.</div>
                  </div>
                  <div className="aw-thumb-button-wrapper">
                    <div className="aw-thumb-button"><a href>INQUIRE</a></div>
                  </div>
                </div>
                <div className="aw-thumbnails">
                  <div className="aw-thumb-title">TV COVERAGE</div>
                  <div className="aw-thumb-img-wrapper">
                    <div className="aw-thumb-img">
                      <img alt="" src="/img/aerial_works/aerial/6.jpg" alt />
                    </div>
                  </div>
                  <div className="aw-thumb-content-wrapper">
                    <div className="aw-thumb-content">As a TV or radio network covering news and local events, you’ll want an excellent view and coverage to report to your viewers or listeners. What better way to cover such events than taking it to the air with top-down
                      views of all the action! Pegasus Air Services can help you cover events such as concerts, movie shoots, local events and activities, or even aerial b-roll footage!</div>
                  </div>
                  <div className="aw-thumb-button-wrapper">
                    <div className="aw-thumb-button"><a href>INQUIRE</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="m-top-40vh" />
        </section>


        <section name="fbo" className="fbo-wrapper fbo-main">
          <div className="fbo-aircaft-services">
            <div data-wow-delay="1s" className="ps-title-wrapper m-top-20vh wow fadeInUp">
              <div className="ps-head-label">FIXED BASE OPERATOR</div>
              <div className="ps-label">AIRCRAFT SERVICES</div>
            </div>
            <div className="m-top-20vh" />
            <div data-wow-delay="1s" className="fbo-copy-wrapper wow fadeInUp">
              <div className="fbo-copy text-gray text-center">Pegasus Air Services Inc. offers a state of the art aeronautical services such as aircraft maintenance, Aircraft Support to Hangaring. We at all levels work continuously to ensure the highest level of technical excellence, on-time
                performance and good personal and professional relationship with clients.</div>
            </div>
            <div className="m-top-20vh" />
            <div data-wow-delay="1s" className="fbo-pic-wrapper wow fadeInUp">
              <div className="fbo-pic"><img alt="" src="/img/fbo/aircraft towing.jpg" /></div>
              <div className="fbo-pic"><img alt="" src="/img/fbo/img20180713095637.jpg" /></div>
              <div className="fbo-pic"><img alt="" src="/img/fbo/baggage cart.jpg" /></div>
            </div>
            <div className="m-top-20vh" />
            <div data-wow-delay="1s" className="fbo-aircraft-services-wrap wow fadeInUp">
              <ul>
                <li><span className="text-torquise">+</span> AIRCRAFT SUPPORT</li>
                <li><span className="text-torquise">+</span> AIRCRAFT / HELICOPTER HANGARAGE</li>
                <li><span className="text-torquise">+</span> HANDLING FOR PISTONS AND TURBO PROPS</li>
                <li><span className="text-torquise">+</span> CATERING SERVICES</li>
                <li><span className="text-torquise">+</span> OXYGEN/NITROGEN SERVICES</li>
                <li><span className="text-torquise">+</span> AIRCRAFT TOWING</li>
              </ul>
              <ul>
                <li><span className="text-torquise">+</span> AIRCRAFT TOWING</li>
                <li><span className="text-torquise">+</span> GROUND AIRCONDITIONING</li>
                <li><span className="text-torquise">+</span> MOBILE AOG SERVICES</li>
                <li><span className="text-torquise">+</span> AIRCRAFT CLEANING (INTERIOR AND EXTERIOR)</li>
                <li><span className="text-torquise">+</span> NOTAMS AND WEATHER BRIEFING</li>
                <li><span className="text-torquise">+</span> RE-FUELING SERVICES</li>
              </ul>
            </div>
          </div>
          <div className="m-top-20vh" />
          <div className="fbo-passenger-services">
            <div className="bg-blue01" />
            <div className="fbo-passenger-cover">
              <div data-wow-delay="1s" className="ps-title-wrapper m-top-20vh wow fadeInUp">
                <div className="ps-head-label-white text-white">FIXED BASE OPERATOR</div>
                <div className="ps-label">PASSENGER SERVICES</div>
              </div>
            </div>
            <div className="fbo-sub-passenger-services">
              <div data-wow-delay="1s" className="fbo-sub wow fadeInUp">OUR CLIENT SATISFACTION IS OUR PRIORITY. </div>
              <div data-wow-delay="1s" className="fbo-hotel wow fadeInUp"><img alt="" src="/img/fbo/room2.jpg" /></div>
            </div>
            <div className="fbo-pic-group">
              <div data-wow-delay="1s" className="fbo-restaurant wow fadeInUp"><img alt="" src="/img/fbo/20170824_101140.jpg" /></div>
              <div data-wow-delay="1s" className="fbo-conference wow fadeInUp"><img alt="" src="/img/fbo/conference_1.jpg" /></div>
            </div>
            <div className="m-top-20vh" />
            <div className="fbo-passenger-services">
              <div data-wow-duration="2s" className="bg-blue02 wow slideInRight" />
              <div className="fbo-passenger-list">
                <ul>
                  <li><span className="text-torquise">+</span> PASSENGER AND CREW HANDLING </li>
                  <li><span className="text-torquise">+</span> BAGGAGE HANDLING</li>
                  <li><span className="text-torquise">+</span> HOTEL ACCOMODATION </li>
                  <li><span className="text-torquise">+</span> WASH UP ROOM FOR PASSENGER OR SNOOZE ROOM FOR PILOTS</li>
                  <li><span className="text-torquise">+</span> AIRSIDE TO CITY TRANSFER(VIA SEDAN, MULTI-PURPOSE VEHICLE OR CREW VAN)</li>
                  <li><span className="text-torquise">+</span> PASSENGER LOUNGE AND SECURED PARKING LOT </li>
                  <li><span className="text-torquise">+</span> RESTAURANT</li>
                  <li><span className="text-torquise">+</span> CONFERENCE ROOM </li>
                  <li><span className="text-torquise">+</span> FUNCTION ROOM</li>
                  <li><span className="text-torquise">+</span> FREE WIFI ACCESS</li>
                  <li><span className="text-torquise">+</span> TRANSIT LOUNGE WITH RANGE OF REFRESHMENTS</li>
                </ul>
              </div>
              <div className="fbo-passenger-pic">
                <div className="fbo-lounge"><img alt="" src="/img/fbo/lounge2 (1).jpg" /></div>
              </div>
            </div>
          </div>
          <div className="m-top-40vh" />
        </section>
        <section name="aircraft-maintenance" className="ac-maintenance-wrapper">
          <div className="ac-avionics">
            <div data-wow-delay="1s" className="ps-title-wrapper m-top-20vh wow fadeInUp">
              <div className="ps-head-label">AIRCRAFT MAINTENANCE</div>
              <div className="ps-label">AVIONICS SERVICES</div>
            </div>
            <div className="avionics-container">
              <div className="line01" />
              <div className="avionic-pics-wrap">
                <div data-wow-delay="1s" className="avionic-pic1 wow fadeInUp"><img alt="" src="/img/ac_maintenance/img20180718103107.jpg" /></div>
                <div data-wow-delay="1s" className="avionic-pic2 wow fadeInUp"><img alt="" src="/img/ac_maintenance/img20180718165220.jpg" /></div>
              </div>
              <div className="avionics-copy-wrap">
                <div data-wow-delay="1s" className="avionics-copy m-top-20vh wow fadeInUp">PASI Avionics Services has served aircraft owners and operators for long period of time. We are renowned for our precision services, tailored to fit the needs of our many clients around the Philippines. From installations and
                  repairs to certifications and diagnostics.</div>
                <hr />
                <div data-wow-delay="1s" className="avionics-list m-top-10vh wow fadeInUp">
                  <ul>
                    <li><span className="text-torquise">+</span> EQUIPMENT INSTALLATION AND REPAIR</li>
                    <li><span className="text-torquise">+</span> RADIO AND INSTRUMENT INSPECTION</li>
                    <li><span className="text-torquise">+</span> PITOT STATIC / ELT / TRANSPONDER CHECK</li>
                    <li><span className="text-torquise">+</span> AIRCRAFT ELECTRICAL WIRING
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="m-top-20vh" />
          <div className="ac-repair-modification">
            <div data-wow-delay="1s" className="ps-title-wrapper m-top-20vh wow fadeInUp">
              <div className="ps-head-label">AIRCRAFT MAINTENANCE</div>
              <div className="ps-label">REPAIR &amp; MODIFICATION</div>
            </div>
            <div className="ac-repair-modification-content">
              <div className="ac-repair-img-wrap">
                <div data-wow-delay="1s" className="ac-repair-img wow fadeInUp"><img alt="" src="/img/ac_maintenance/20181022_155935.jpg" /></div>
                <div data-wow-delay="1s" className="ac-repair-img wow fadeInUp"><img alt="" src="/img/ac_maintenance/img20180713082905.jpg" /></div>
                <div data-wow-delay="1s" className="ac-repair-img wow fadeInUp"><img alt="" src="/img/ac_maintenance/20170907_102027.jpg" /></div>
              </div>
              <div className="m-top-10vh" />
              <div data-wow-delay="1s" className="ac-repair-copy-wrap wow fadeInUp">
                <div className="ac-repair-copy text-gray text-center">Safe flying needs dependable and quality maintenance team. Pegasus Air Services Inc. is a certified CAAP Approved Maintenance Organization (AMO) in the Philippines which is just within reach to maintain training aircraft, corporate
                  and non-corporate aircraft.</div>
              </div>
              <div className="m-top-10vh" />
              <div data-wow-delay="1s" className="ac-repair-list wow fadeInUp">
                <ul>
                  <li><span className="text-torquise">+</span> REPAIR AND LINE MAINTENANCE</li>
                  <li><span className="text-torquise">+</span> POWER PLANT INSPECTION AND REPAIR </li>
                  <li><span className="text-torquise">+</span> AIRCRAFT UPHOLSTERY</li>
                  <li><span className="text-torquise">+</span> CORROSIVE PREVENTION AND CONTROL PROGRAM (CPCP)</li>
                  <li><span className="text-torquise">+</span> REMOTE VISUAL INSPECTION (BOROSCOPE / ENDOSCOPE INSPECTION)</li>
                  <li><span className="text-torquise">+</span> COMPASS CALIBRATION </li>
                </ul>
                <ul>
                  <li><span className="text-torquise">+</span> HELICOIL REMOVAL AND INSTALLER</li>
                  <li><span className="text-torquise">+</span> CYLINDER AND VALVE GUIDE HONING </li>
                  <li><span className="text-torquise">+</span> LIFE VEST AND LIFE RAFT INSPECTION &amp; REPACKING</li>
                  <li><span className="text-torquise">+</span> WEIGHT AND BALANCE OF AIRPLANE AND HELICOPTER (UP TO 300000 lbs)</li>
                  <li><span className="text-torquise">+</span> PRE-PURCHASED INSPECTION</li>
                  <li><span className="text-torquise">+</span> ANNUAL RE-LICENSING INSPECTION</li>
                </ul>
              </div>
              <div className="m-top-20vh" />
            </div>
          </div>
          <div className="ac-ndt">
            <div className="ac-bg"> </div>
            <div data-wow-delay="1s" className="ps-title-wrapper m-top-20vh wow fadeInUp">
              <div className="ps-head-label">AIRCRAFT MAINTENANCE</div>
              <div className="ps-label">NON-DESTRUCTIVE INSPECTION (NDT)</div>
            </div>
            <div className="m-top-10vh" />
            <div className="ac-ndt-content">
              <div className="flex-display">
                <div className="ac-ndt-set1">
                  <div data-wow-delay="1s" className="ac-ndt-img-set1 wow fadeInUp">
                    <div className="ac-ndt-img1"><img alt="" src="/img/ac_maintenance/img20200323163147.jpg" /></div>
                    <div className="ac-ndt-img1"><img alt="" src="/img/ac_maintenance/img20200206151456.jpg" /></div>
                    <div className="ac-ndt-img1"><img alt="" src="/img/ac_maintenance/img20200219092855.jpg" /></div>
                  </div>
                  <div className="m-top-10vh" />
                  <div data-wow-delay="1s" className="ac-ndt-copy-wrap wow fadeInUp">
                    <div className="ac-ndt-copy">Pegasus Air Services Non-Destructive Testing - Ensures the safety and efficient of the Aircraft by detecting defects and corrosion before they result in severe damage, and assures compliance with Civil Aviation Authority
                      of the Philippines standards.</div>
                  </div>
                  <div className="m-top-10vh" />
                  <div className="ac-ndt-list">
                    <ul data-wow-delay="1s" className="wow fadeInUp">
                      <li><span className="text-torquise">+</span> LIQUID PENETRANT TESTING
                        <ul className="sub-list">
                          <li>- MAGNETIC PARTICLE TESTING</li>
                          <li>- FLOURESCENT LAMP INSPECTION</li>
                        </ul>
                      </li>
                      <li><span className="text-torquise">+</span> EDDY CURRENT TESTING</li>
                      <li><span className="text-torquise">+</span> ULTRASONIC INSPECTION
                        <ul className="sub-list">
                          <li>- THICKNESS GAUGING</li>
                          <li>- FLAW SIZING (FLAW DETECTION)</li>
                        </ul>
                      </li>
                      <li><span className="text-torquise">+</span> RADIO GRAPHIC INSPECTION (X-RAY)</li>
                      <li><span className="text-torquise">+</span> VISUAL INSPECTION (BOROSCOPE )
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ac-ndt-set2">
                  <div data-wow-duration="3s" className="ac-bg2 wow slideInRight" />
                  <div className="ac-ndt-img-set2 m-top-20vh">
                    <div className="ac-ndt-img2"><img alt="" src="/img/ac_maintenance/img20200219092004.jpg" /></div>
                    <div className="ac-ndt-img2"><img alt="" src="/img/ac_maintenance/img20200319173907.jpg" /></div>
                  </div>
                </div>
              </div>
              <div className="m-top-20vh" />
            </div>
          </div>
          <div className="ac-overhaul">
            <div className="ac-overhaul-head">
              <div className="ac-title-sub">AIRCRAFT ENGINE &amp; MAINTENANCE</div>
              <div className="ac-title-bg">OVERHAUL</div>
              <hr />
              <div className="ac-title-min-sub">WE INTEND TO GIVE OUR BEST TO OUR CLIENTS <br /> TOWARD FULL SPECTRUM OF OVERHAUL SERVICES</div>
            </div>
            <div className="ac-overhaul-body">
              <div className="ac-overhaul-bg" />
              <div className="aco-wrap">
                <div className="aco-panel1">
                  <div className="aco-img01"><img alt="" src="/img/ac_maintenance/img20200212180839.jpg" /></div>
                  <div className="aco-img02"><img alt="" src="/img/ac_maintenance/img20180718112431.jpg" /></div>
                </div>
                <div className="aco-panel2">
                  <div className="aco-copy-wrap">
                    <div className="aco-sub-title">PEGASUS AIR SERVICES INC.</div>
                    <div className="aco-title">ENGINE OVERHAUL</div>
                    <div className="m-top-20vh" />
                    <hr />
                    <div className="aco-copy">Pegasus Air Services Inc. specialized in both Lycoming and Continental Piston aircraft Engines.
                      <br /><br /> Dedicated to provide excellent and quality Engine Overhaul with our well-trained professional personnel and complete equipment to test and overhaul an Aircraft Engine complemented by the Engine Test Cell.</div>
                    <div className="aco-img3"><img alt="" src="/img/ac_maintenance/etc2.jpg" /></div>
                  </div>
                </div>
              </div>
              <div className="aco-wrap2">
                <div className="aco-panel3">
                  <div className="aco-img04"><img alt="" src="/img/ac_maintenance/engine_test_cell.png" /></div>
                </div>
                <div className="aco-panel4">
                  <div className="m-top-20vh" />
                  <ul className="desc-overhaul">
                    <li><span><img alt="" src="/img/icons/Icon ionic-ios-man.png" /></span> Well trained personnel with license in both Lycoming and Continental Engine Overhaul</li>
                    <li><span><img alt="" src="/img/icons/Icon simple-gravatar.png" /></span> Complete Overhaul and Testing equipment provinding you a state of the art services</li>
                    <li><span><img alt="" src="/img/icons/Icon awesome-newspaper.png" /></span> Precise and Good Engine Condition Monitoring and Assessment supported with certificates</li>
                  </ul>
                  <div className="m-top-10vh" />
                </div>
              </div>
            </div>
          </div>
          <div className="m-top-20vh"> </div>
          <div className="ac-a-o1">
            <div className="ac-a-o1-panel">
              <div className="ac-a-o1-title">
                <div className="ac-a-o1-title-sm">PEGASUS AIR SERVICES INC.</div>
                <div className="ac-a-o1-title-bg">ACCESSORIES OVERHAUL</div>
              </div>
              <hr />
              <ul>
                <li><span className="text-torquise">+</span> CARBURATOR OVERHAUL</li>
                <li><span className="text-torquise">+</span> MANIFOLD VALVE OVERHAUL</li>
                <li><span className="text-torquise">+</span> SERVO FUEL OVERHAUL</li>
                <li><span className="text-torquise">+</span> FUEL PUMP OVERHAUL</li>
                <li><span className="text-torquise">+</span> AUX FUEL PUMP OVERHAUL</li>
                <li><span className="text-torquise">+</span> FUEL NOSSLE TESTING
                </li>
              </ul>
            </div>
            <div className="ac-a-o1-panel">
              <div className="ac-a-01-bg-blue" />
              <div className="m-top-10vh" />
              <div className="ac-a-img1"><img alt="" src="/img/ac_maintenance/img20200318133946_1.jpg" /></div>
            </div>
          </div>
          <div className="m-top-10vh" />
          <hr />
          <div className="m-top-10vh" />
          <div className="ac-a-o1">
            <div className="ac-a-o1-panel">
              <ul>
                <li><span className="text-torquise">+</span> ALTERNATOR &amp; CARBURATOR TESTING &amp; OVERHAUL</li>
                <li><span className="text-torquise">+</span> BATTERY CHECK</li>
                <li><span className="text-torquise">+</span> GENERATOR TESTING &amp; OVERHAUL</li>
                <li><span className="text-torquise">+</span> MAGNETO TESTING &amp; OVERHAUL</li>
                <li><span className="text-torquise">+</span> STARTER TESTING &amp; OVERHAUL</li>
                <li><span className="text-torquise">+</span> TURBO &amp; ACCESSORIES TESTING &amp; OVERHAUL</li>
              </ul>
            </div>
            <div className="ac-a-o1-panel">
              <div className="ac-a-01-bg-blue" />
              <div className="m-top-10vh" />
              <div className="ac-a-img1"><img alt="" src="/img/ac_maintenance/accessories engine.jpg" /></div>
            </div>
          </div>
          <div className="m-top-20vh" />
        </section>
      </div>
    
      <Footer />
      
    </div>

  );
}

export default App;
