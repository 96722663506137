import React from 'react';

import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faYoutube, faInstagram } from '@fortawesome/free-brands-svg-icons';

function Footer () {

    return (
        <section className="footer-wrapper wow slideInUp">
            <div className="footer-container">
                <Container>
                    <Grid container
                        direction="row"
                        justify="center"
                        alignItems="stretch"
                        maxWidth="sm"

                        lg={12} 
                        spacing={3} >
                        <Grid  item xs={12} lg={4} spacing={3} >
                            <div className="f-item-wrapper">
                                <div className="footer-logo">
                                    <img src="./img/general/pegasus_logo_white.png" alt=""  />
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} lg={4} spacing={3} >
                            <div className="f-item-wrapper">
                                <ul className="footer-list">
                                    <li>
                                        AERIAL WORKS
                                    </li>
                                    <li>
                                        FIXED BASE-OPERATOR
                                    </li>
                                    <li>
                                        AIRCRAFT MAINTENANCE
                                    </li>
                                </ul>
                                <hr />
                                <div className="icon-holders-footer">
                                    <div className="ficons">
                                        <FontAwesomeIcon icon={faFacebook} size="3x" color="white"  />
                                    </div>
                                    <div className="ficons">
                                        <FontAwesomeIcon icon={faTwitter} size="3x" color="white" />
                                    </div>
                                    <div className="ficons">
                                    < FontAwesomeIcon icon={faYoutube} size="3x" color="white" />
                                    </div>
                                    <div className="ficons">
                                        <FontAwesomeIcon icon={faInstagram} size="3x" color="white" />
                                    </div>  
                                </div>


                            </div>
                        </Grid>
                        <Grid item xs={12} lg={4} spacing={3} >
                            <div className="f-item-wrapper">
                            <div className="ft-01">
                            <div className="ft-title">MAIN OFFICE:</div>
                            <div className="ft-copy">PEGASUS AIR SERVICES HANGAR,
                                <br /> PLARIDEL AIRPORT, BARANGAY LUMANG BAYAN,
                                <br /> PLARIDEL PHILIPPINES 3007
                                <br />
                                <br /> Tel. No.: +63 (0) 44. 794.55.73 
                                <br /> Mobile No.: +63 (0) 919.081.8120
                                <br /> Mobile No.: +63 (0) 919.081.8120
                            </div>
                            </div>
                            <hr />
                            <div className="ft-01">
                            <div className="ft-title">MAIN OFFICE:</div>
                            <div className="ft-copy">PEGASUS AIR SERVICES HANGAR,
                                <br /> INTERISLAND RESORTS & SERVICES INC. OFFICE
                                <br /> DOMESTIC ROAD, PASAY CITY 
                                <br />
                                <br /> Tel. No.: +63 (0) 2 851.17.31
                                <br /> Mobile No.: +63 (0) 921.467.6678
                                <br /> Mobile No.: +63 (0) 966.213.9267
                            </div>
                            </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            
            </div>
            <div className="footer-line">
                Copyright © 2020 Pegasus Air Services Inc.
            </div>
      </section>
    );
}

export default Footer;
